:root {
  --PrimaryColor: hsl(26,93%,50%);
  --HoverColor: hsl(26,90%,42%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,10%);
  --textColor: hsl(240,1%,48%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0,0%,96%);
  --greyText: rgb(190,190,190);
  --inputColor: hsl(330,12%,97%);
}

.chat-popup {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 400px;
  height: auto; /* Adjust height as needed */
  background-color: #f2f2f2;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  z-index: 999;
  font-size: small;
}
.chat-popup.expanded {
  height: 70vh; /* Adjust expanded height as needed */
}
.chat-popup .chat-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8rem;
  cursor: pointer;
  background-color: #f2f2f2;
  border-bottom: 1px solid #ccc;
}
.chat-popup .chat-header h3 {
  margin: 0;
  font-size: 16px;
}
.chat-popup .chat-header .notification-dot {
  width: 20px;
  height: 20px;
  color: red;
}
.chat-popup .chat-body {
  background-color: #f2f2f2;
  height: 60vh;
  padding: 0.5rem;
  overflow: scroll;
  display: flex;
  flex-direction: column;
}
.chat-popup .chat-body .chat-messages {
  flex-grow: 1;
  overflow-y: auto;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 5px;
  display: flex;
  width: 100%;
  flex-direction: column;
}
.chat-popup .chat-body .chat-messages .chat-message {
  margin-bottom: 10px;
  padding: 8px;
  border-radius: 5px;
  max-width: 70%;
}
.chat-popup .chat-body .chat-messages .chat-message.received {
  background-color: #e0e0e0;
  align-self: flex-start;
}
.chat-popup .chat-body .chat-messages .chat-message.sent {
  background-color: var(--PrimaryColor);
  color: #fff;
  align-self: flex-end;
}
.chat-popup .chat-body .chat-input {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.chat-popup .chat-body .chat-input input[type=text] {
  flex-grow: 1;
  border: none;
  outline: none;
  background: none;
  padding: 1rem 2rem;
  background-color: var(--whiteColor);
  width: 50%;
}
.chat-popup .chat-body .chat-input button {
  margin-left: 1rem;
  padding: 1rem 2rem;
  background-color: var(--PrimaryColor);
  color: var(--whiteColor);
  border: none;
  border-radius: 3px;
  cursor: pointer;
}
.chat-popup .chat-body .chat-input button:hover {
  color: var(--whiteColor);
  background-color: var(--HoverColor);
}

@media screen and (max-width: 440px) {
  .chat-popup {
    width: auto;
    margin: 1rem;
    bottom: 0;
    right: 0;
    left: 0;
  }
}/*# sourceMappingURL=chatpopup.css.map */