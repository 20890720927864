:root {
  --PrimaryColor: hsl(26,93%,50%);
  --HoverColor: hsl(26,90%,42%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,10%);
  --textColor: hsl(240,1%,48%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0,0%,96%);
  --greyText: rgb(190,190,190);
  --inputColor: hsl(330,12%,97%);
}

.popular {
  padding-top: 15rem;
  background-color: transparent;
}
.popular .secContainer {
  background-color: transparent;
}
.popular .secContainer .secHeader {
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}
.popular .secContainer .secHeader .textDiv .secTitle {
  line-height: 1.5rem;
  padding-bottom: 0.5rem;
}
.popular .secContainer .secHeader .textDiv p {
  font-size: 13px;
  color: var(--textColor);
  font-weight: 500;
}
.popular .secContainer .secHeader .iconDiv {
  gap: 0.5rem;
}
.popular .secContainer .secHeader .iconDiv .icon {
  padding: 0.5rem;
  background: var(--greyText);
  border-radius: 50%;
  font-size: 2.5rem;
  cursor: default;
}
.popular .secContainer .mainContent {
  background-color: transparent;
  padding: 2rem 0;
  gap: 1rem;
}
.popular .secContainer .mainContent .singleDestination {
  overflow: hidden;
  border-radius: 1rem;
  box-shadow: 0 2px 8px 2px rgba(178, 179, 178, 0.334);
}
.popular .secContainer .mainContent .singleDestination .destImage {
  height: 200px;
  width: 100%;
  position: relative;
  overflow: hidden;
}
.popular .secContainer .mainContent .singleDestination .destImage img {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}
.popular .secContainer .mainContent .singleDestination .destImage .overlayInfo {
  position: absolute;
  display: flex;
  align-items: flex-start;
  padding: 0;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0.846), rgba(0, 0, 0, 0.759));
  bottom: 0;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.popular .secContainer .mainContent .singleDestination .destImage .overlayInfo h3 {
  color: var(--whiteColor);
  font-weight: 600;
}
.popular .secContainer .mainContent .singleDestination .destImage .overlayInfo p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 13px;
}
.popular .secContainer .mainContent .singleDestination .destImage .overlayInfo .icon {
  position: absolute;
  right: 10px;
  bottom: 10px;
  padding: 0.5rem;
  background: var(--PrimaryColor);
  font-size: 2.5rem;
  border-radius: 50%;
  color: var(--whiteColor);
  border: 1px solid var(--PrimaryColor);
  transition: 0.3s ease;
}
.popular .secContainer .mainContent .singleDestination .destImage .overlayInfo .icon:hover {
  negative: var(--blackColor);
  border: 1px solid var(--PrimaryColor);
  color: var(--PrimaryColor);
}
.popular .secContainer .mainContent .singleDestination .destFooter {
  padding: 1rem;
}
.popular .secContainer .mainContent .singleDestination .destFooter .number {
  font-size: 1rem;
  font-weight: 400;
  color: var(--blackColor);
  position: relative;
  width: -moz-max-content;
  width: max-content;
}
.popular .secContainer .mainContent .singleDestination .destFooter .number::after {
  content: "";
  position: absolute;
  background: var(--PrimaryColor);
  top: 45%;
  right: -150%;
  height: 5px;
  width: 100%;
  border-radius: 1rem;
}
.popular .secContainer .mainContent .singleDestination .destFooter .destText {
  justify-content: space-between;
}
.popular .secContainer .mainContent .singleDestination .destFooter .destText h6 {
  color: var(--blackColor);
  font-size: 15px;
}
.popular .secContainer .mainContent .singleDestination .destFooter .destText .flex {
  font-size: 16px;
  font-weight: 600;
  align-items: flex-start;
  transform: translateY(5px);
}
.popular .secContainer .mainContent .singleDestination .destFooter .destText .flex .icon {
  color: var(--PrimaryColor);
}
.popular .secContainer .mainContent .singleDestination:hover .overlayInfo {
  overflow: visible;
  padding: 1rem 1.5rem;
  height: 100%;
}

@media screen and (min-width: 480px) {
  .popular {
    padding-top: 10rem;
  }
}
@media screen and (max-width: 720px) {
  .popular {
    margin-top: 5rem;
  }
}
@media screen and (max-width: 480px) {
  .popular {
    margin-top: 10rem;
  }
}
@media screen and (min-width: 556px) {
  .popular .secContainer .secHeader {
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .popular .secContainer .secHeader .textDiv {
    text-align: start;
    max-width: 70%;
  }
  .popular .secContainer .secHeader .iconDiv {
    justify-content: flex-end;
  }
}
@media screen and (min-width: 720px) {
  .popular {
    padding-top: 7rem;
  }
}
@media screen and (min-width: 1024px) {
  .popular {
    padding-top: 10rem;
  }
  .popular .secContainer .secHeader .textDiv .secTitle {
    font-size: 2.2rem;
  }
  .popular .secContainer .secHeader .textDiv p {
    font-size: 15px;
  }
  .popular .secContainer .mainContent {
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem !important;
  }
}/*# sourceMappingURL=popular.css.map */