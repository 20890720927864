:root {
    --PrimaryColor: hsl(26,93%,50%);
    --HoverColor: hsl(26,90%,42%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,10%);
    --textColor: hsl(240,1%,48%);
    --whiteColorDeam: hsl(0,0%,93%);
    --greyBg: hsl(0,0%,96%);
    --greyText: rgb(190,190,190);
    --inputColor: hsl(330,12%,97%);
}


.blogHome{
    height: 50vh;
    background: linear-gradient(rgba(33,33,33,0.522),rgba(33,33,33,0.522),rgba(33,33,33,0.522)),url('../../../../Assets/pexels-pixabay-70080.jpg');
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    position: relative;

    .secContainer{

        .blogCard{
            width: 40%;
            border-radius: 1rem;
            padding: 2rem;
            background: var(--whiteColor);
            position: absolute;
            gap: 1rem;
            justify-self: center;
            bottom: 25%;
            transform: translate(-50%);
            left: 50%;

             .searchDiv {
                width: 100%;

                label{
                    display: block;
                    color: var(--textColor);
                    font-weight: 500;
                    font-size: 13px;
                    padding-bottom: .4rem;
                    width: 150%;
                }

                input{
                    width: 150%;
                    background: var(--inputColor);
                    border-radius: 10px;
                    border: none;
                    padding: 10px;
                    font-size: 13px;
                    font-weight: 500;

                    &::placeholder{
                        font-size: 13px;
                        opacity: .5;
                        font-weight: 400;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }

            .btn{
                width: 50%;
                padding: .6rem 1.5rem;
                margin-left: 50%;
                color: var(--whiteColor);
                font-weight: 600;
            }
        }
    }

}


@media screen and (max-width: 3000px) {

    .blogHome{
        .secContainer{
            .blogCard{
                // bottom: -100px;
                // width: 40%;
                grid-template-columns: repeat(2,1fr);

                .btn{
                    transform: translateY(15px);
                }
            }
        }
    }
    
}

@media screen and (max-width: 1024px) {
    .blogHome{
        .secContainer{
            .blogCard{
                width: 40%;

                .btn{
                    padding: .6rem .6rem;
                }
            }
        }
        
    }
    
}

@media screen and (max-width: 930px) {
    .blogHome{
        .secContainer{
            .blogCard{
                
                .searchDiv{
                    input{
                        width: 140%;
                    }


                }

                .btn{
                    width: 60%;
                    transform: translateY(20px);
                    margin-left: 40%;

                }
            }
        }
        
    }
    
}

@media screen and (max-width: 720px) {

    .blogHome{
        .secContainer{
            .blogCard{
            width: 50%;
            transform: translate(-50%);
            grid-template-columns: repeat(1,1fr);

                
            .searchDiv{
                label{
                    width: 100%;
                }
                input{
                    width: 100%;
                }
            }
            .btn{
                width: 100%;
                margin-top: -10%;
                margin-left: 0%;
            }

            }
        }
    }
}

@media screen and (max-width: 480px) {

    .blogHome{
        .secContainer{
            .blogCard{
            width: 60%;

            }
        }
    }
}