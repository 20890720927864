:root {
  --PrimaryColor: hsl(26,93%,50%);
  --HoverColor: hsl(26,90%,42%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,10%);
  --textColor: hsl(240,1%,48%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0,0%,96%);
  --greyText: rgb(190,190,190);
  --inputColor: hsl(330,12%,97%);
  --boxShadow: box-shadow: 0 2px 8px 2px rgba(178,178,178,0.189);
}

.about .secContainer .title {
  color: var(--PrimaryColor);
  line-height: 1.5rem;
  padding-bottom: 2rem;
  text-align: center;
  font-size: 1.5rem;
  font-weight: 700;
}
.about .secContainer .mainContent {
  gap: 1rem;
}
.about .secContainer .mainContent .singleItem {
  text-align: center;
}
.about .secContainer .mainContent .singleItem img {
  background: var(--PrimaryColor);
  width: 100px;
  height: 100px;
  border-radius: 50%;
  padding: 0.2rem;
}
.about .secContainer .mainContent .singleItem h3 {
  font-weight: 600;
  color: var(--blackColor);
  padding: 0.5rem;
}
.about .secContainer .mainContent .singleItem p {
  font-size: 13px;
  font-weight: 500;
  padding-bottom: 0.5rem;
  color: var(--textColor);
}
.about .secContainer .videoCard {
  margin-top: 2rem;
  background: linear-gradient(rgba(0, 0, 0, 0.73), rgba(0, 0, 0, 0.609)), url("../../../../Assets/pexels-asad-photo-maldives-240512-min.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}
.about .secContainer .videoCard .cardContent {
  gap: 2rem;
  padding: 2rem 1rem;
}
.about .secContainer .videoCard .cardContent .cardText h2 {
  color: var(--whiteColor);
  font-weight: 600;
  padding-bottom: 1rem;
}
.about .secContainer .videoCard .cardContent .cardText p {
  color: var(--whiteColor);
  opacity: 0.7;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1;
}
.about .secContainer .videoCard .cardVideo {
  border: 2px solid var(--greyText);
  height: 200px;
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.about .secContainer .videoCard .cardVideo video {
  height: 100%;
  width: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

@media screen and (min-width: 720px) {
  .about .secContainer .videoCard .cardContent {
    gap: 4rem;
    padding: 4rem 3rem;
  }
  .about .secContainer .videoCard .cardContent .cardText h2 {
    font-size: 2rem;
  }
  .about .secContainer .videoCard .cardContent .cardText p {
    opacity: 0.7;
    font-size: 15px;
  }
  .about .secContainer .videoCard .cardContent {
    grid-template-columns: repeat(2, 1fr) !important;
  }
}
@media screen and (min-width: 1024px) {
  .about .secContainer {
    padding: 2.5rem 0;
  }
}/*# sourceMappingURL=about.css.map */