:root {
    --PrimaryColor: hsl(26,93%,50%);
    --HoverColor: hsl(26,90%,42%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,10%);
    --textColor: hsl(240,1%,48%);
    --whiteColorDeam: hsl(0,0%,93%);
    --greyBg: hsl(0,0%,96%);
    --greyText: rgb(190,190,190);
    --inputColor: hsl(330,12%,97%);
}


.package-page {
    display: flex;
    justify-content: space-between;
    text-align: left;
  background-color: transparent;
    .package-details {

      width: 70%;
      padding: 1rem;
      margin-bottom: 8rem;
  
      .day {
        .image {
          width: 100%;
          padding: 1rem;
          /* Style the image container */
        }
  
        .description {
          width: 100%;
          height: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          /* Style the description container */
        }
      }
      .daysTitle{
        width:150%;
      }


      .daysNavigationArrows{

        width:50%;
        height:20%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 0.5rem;
        .icon{
            padding: .5rem;
            background: var(--greyText);
            border-radius: 50%;
            font-size: 2.5rem;
            &:hover{
                color: var(--whiteColor);
                background: var(--blackColor);
                column-rule: var(--whiteColor);
            }
        }

      }

  
      .includes {
        /* Style the inclusions container */
        margin-bottom: 1rem;
      }

     
    }
  
    .package-purchase {
      width: 30%;
      /* Style the purchase container */
 
      .btn{
        margin-top: 1rem;
        width: 100%;
        text-align: center;
        justify-content: center;
        padding: .6rem;
        color:var(--whiteColor);
        font-weight: 600;
        gap: .5rem;


        .icon{
            color: var(--whiteColor);
        }

    }

      .singleAccomodation{
        border-radius: 1rem;
        overflow: hidden;
        padding: .5rem;
        box-shadow: 0 2px 8px 2px rgba(178,178,178,0.189);

        .icon{
            font-size: 20px;
            color: var(--textColor);
        }

        small{
            font-weight: 500;
            color:var(--textColor);
        }

        .destImage{
            position: relative;
            height: 200px;
            width: 100%;
            border-radius: 1rem;
            overflow: hidden;

            img{
                height: 100%;
            }

            .discount{
                position: absolute;
                top: 10px;
                right: 10px;
                padding: 5px 10px;
                background: var(--PrimaryColor);
                color: var(--whiteColor);
                border-radius: 3rem;
                font-size: 10px;
                font-weight: 500;
            }
        }

        .AccomodationBody{
            padding: 1rem;

            .price{
                justify-content: space-between;

                h4{
                    color: var(--blackColor);
                    font-weight: 700;
                    font-size: 1.1rem;
                }

                .status{
                    padding: 5px 10px;
                    color: var(--HoverColor);
                    background: var(--greyBg);
                    font-size: 10px;
                    font-weight: 600;
                    border-radius: 3rem;
                }
            }

            .amenities{
                width: 100%;
                flex-wrap: wrap;
                justify-content: flex-start;
                padding: 1rem 0;
                gap: .5rem;

                .singleAmenity{
                    padding: 5px 10px;
                    border-radius: 5px;
                    background: var(--greyBg);
                    gap: .5rem;
                }

            }

            
        }
    }
    }
  }
  



  @media screen and (max-width: 870px) {

    .package-page {
       display: block;
       .package-details {
        width: 100%;
       }

       .package-purchase {
        width: 100%;
        .singleAccomodation{

          .destImage{

            img{
              width: 100%;
              height: 100%; /* Ensure the image fills the height of the container */
              object-fit: cover; 

            }
          }
        }
       }

    }

  }