.modalBackground {
    width: 100%;
    height: 100vh;
    background-color: rgba(5, 5, 5, 0.224);
    position: fixed;
    z-index: 90000000;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
   inset: 0;
  }
  
  .modalContainer {
    width: auto;
    height: auto;
    border-radius: 12px;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    display: flex;
    flex-direction: column;
    padding: 25px;
  }
  
  .modalContainer .title {
    display: inline-block;
    text-align: center;
    padding-left: 20%;
    margin-top: 0;
    width: 80%;
    position: relative;
    top: -20px;
 
  }
  
  .titleCloseBtn {
    display: flex;
    width: 15%;
    margin-left: 85%;
    justify-content: flex-end;
  }
  
  .titleCloseBtn button {
    background-color: transparent;
    color: red;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  



