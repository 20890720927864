:root {
    --PrimaryColor: hsl(26,93%,50%);
    --HoverColor: hsl(26,90%,42%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,10%);
    --textColor: hsl(240,1%,48%);
    --whiteColorDeam: hsl(0,0%,93%);
    --greyBg: hsl(0,0%,96%);
    --greyText: rgb(190,190,190);
    --inputColor: hsl(330,12%,97%);
    --boxShadow: box-shadow: 0 2px 8px 2px rgba(178,178,178,0.189);
}


.footer{
    background: var(--greyBg);
    padding: 2rem 0;

    .secContainer{
        gap: 1.5rem;
        text-align: center;

        .footerLogo{ 
            padding-bottom: .5rem;
            .logo{
                color: var(--blackColor);
                font-weight: 500;
                cursor: pointer;
                align-items: center;
                justify-content: center;

                .icon{
                    font-size: 25px;
                    color: var(--PrimaryColor);
                    margin-right: .5rem;
                }
            }
        }


        .socials{
            gap: .5rem;
            margin: auto;
            justify-content: center;


            .icon{
                font-size: 30px;
                background: var(--PrimaryColor);
                border-radius: 50%;
                padding: 8px;
                color: var(--whiteColor);
            }

            .customLogo{
                height: 25px;
                width: 25px;
            }
        }

        .footerLinks{
            display: grid;
            row-gap: .2rem;

            .linkTitle{
                padding-bottom: .5rem;
                color: var(--blackColor);
                font-weight: 700;
            }

            

            li{
                .active{
                    color: var(--PrimaryColor);
                }
                
                &:hover{
                    transform: translateX(10px);

                    a{
                        color: var(--PrimaryColor);
                     }
                }

               
            }

            a, .phone, .email {
                color: var(--textColor);
                font-size: 13px;
                font-weight: 500;
            }

            input{
                width: 100%;
                background: var(--whiteColor);
                border-radius: 10px;
                border: none;
                padding: 5px;
                margin: 5px;
                font-size: 13px;
                font-weight: 500;

                &::placeholder{
                    font-size: 13px;
                    opacity: .5;
                    font-weight: 400;
                }

                &:focus {
                    outline: none;
                }
            }
        }
    }
}



@media screen and (min-width: 448px) {
    .secContainer{
        grid-template-columns: repeat(2,1fr);
        text-align: center;
        align-items: flex-start;

        .footerLinks{
            justify-content: center;
        }
    }
    
}


@media screen and (min-width: 640px) {
    .secContainer{
        grid-template-columns: repeat(4,1fr);
    }
}


@media screen and (min-width: 900px) {
    .secContainer{
        padding: 2rem;
    }
}