:root {
  --PrimaryColor: hsl(26,93%,50%);
  --HoverColor: hsl(26,90%,42%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,10%);
  --textColor: hsl(240,1%,48%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0,0%,96%);
  --greyText: rgb(190,190,190);
  --inputColor: hsl(330,12%,97%);
}

.contactUsForm {
  width: 60%;
  padding: 2rem 3rem;
  gap: 1rem;
  grid-template-columns: repeat(2, 1fr);
}
.contactUsForm .inputField {
  margin-top: 2rem;
}
.contactUsForm .inputField label {
  display: block;
  color: var(--textColor);
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 0.4rem;
}
.contactUsForm .inputField textarea, .contactUsForm .inputField input {
  width: 100%;
  max-width: 100%;
  background: var(--inputColor);
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}
.contactUsForm .inputField textarea::-moz-placeholder, .contactUsForm .inputField input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.contactUsForm .inputField textarea::placeholder, .contactUsForm .inputField input::placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.contactUsForm .inputField textarea:focus, .contactUsForm .inputField input:focus {
  outline: none;
}
.contactUsForm .btn {
  width: 40%;
  justify-self: center;
  padding: 0.6rem 1.5rem;
  margin-top: 2rem;
  color: var(--whiteColor);
  font-weight: 600;
}

.userAuthentication {
  width: 400px;
  padding: 2rem 3rem;
  gap: 1rem;
  justify-self: center;
  grid-template-columns: repeat(2, 1fr);
}
.userAuthentication .inputField {
  margin-top: 2rem;
}
.userAuthentication .inputField label {
  display: block;
  color: var(--textColor);
  font-weight: 500;
  font-size: 13px;
  padding-bottom: 0.4rem;
}
.userAuthentication .inputField input {
  width: 100%;
  background: var(--inputColor);
  border-radius: 10px;
  border: none;
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
}
.userAuthentication .inputField input::-moz-placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.userAuthentication .inputField input::placeholder {
  font-size: 13px;
  opacity: 0.5;
  font-weight: 400;
}
.userAuthentication .inputField input:focus {
  outline: none;
}
.userAuthentication .btn {
  width: 100%;
  padding: 0.6rem 1.5rem;
  margin-top: 2rem;
  color: var(--whiteColor);
  font-weight: 600;
}

.navBarSection .header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  width: 100%;
  padding: 1rem;
  z-index: 1000;
}
.navBarSection .header .logoDiv .logo {
  color: var(--PrimaryColor);
  font-weight: 500;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.navBarSection .header .logoDiv .logo .icon {
  color: var(--PrimaryColor);
  font-size: 25px;
  margin-right: 10px;
  height: 25px;
  width: 25px;
}
.navBarSection .header .singleCustomer {
  align-items: flex-start;
  justify-content: space-between;
}
.navBarSection .header .singleCustomer img {
  width: 40px;
  height: 40px;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 50%;
  box-shadow: 0 2px 4px var(--itemCardColor);
  margin-right: 1rem;
}
.navBarSection .header .singleCustomer .customerDetails {
  flex: 1;
}
.navBarSection .header .singleCustomer .customerDetails .name {
  font-size: var(--h3FontSize);
  color: var(--textColor);
  font-size: 0.9rem;
  font-weight: 600;
  display: block;
}
.navBarSection .header .singleCustomer .customerDetails small {
  color: var(--textColor);
  font-size: 0.7rem;
  font-size: var(--smallFontSize);
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .navBarSection .header .navBar {
    background: var(--greyBg);
    position: absolute;
    height: -moz-max-content;
    height: max-content;
    padding: 1rem;
    width: 100%;
    top: 0;
    left: -500%;
    box-shadow: 0 2px 4px rgba(200, 209, 209, 0.994);
    transition: 0.5s ease;
    z-index: 1000;
  }
  .navBarSection .header .navBar .navLists {
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: auto;
  }
  .navBarSection .header .navBar .navLists .navItem {
    padding: 1rem 0;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink {
    color: var(--textColor);
    font-size: 0.9rem;
    font-weight: 600;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink:hover {
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .navItem .active {
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .headerBtns {
    flex-direction: column;
  }
  .navBarSection .header .navBar .navLists .headerBtns .btn {
    margin-top: 1rem;
  }
  .navBarSection .header .navBar .navLists .headerBtns .btn a {
    font-weight: 600;
  }
  .navBarSection .header .navBar .navLists .headerBtns .btn a:hover {
    color: var(--whiteColor);
  }
  .navBarSection .header .navBar .navLists .headerBtns .loginBtn {
    background: none;
    border: 1px solid var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .headerBtns .loginBtn a {
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .headerBtns .loginBtn:hover {
    background: var(--HoverColor);
  }
  .navBarSection .header .navBar .navLists .headerBtns .loginBtn:hover a {
    color: var(--whiteColor);
  }
  .navBarSection .header .navBar .closeNavbar {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .closeNavbar:hover .icon {
    color: var(--HoverColor);
  }
  .navBarSection .header .activeNavbar {
    left: 0;
  }
}
.navBarSection .header .toggleNavbar .icon {
  font-size: 25px;
  color: var(--whiteColor);
}
.navBarSection .header .toggleNavbar .icon:hover {
  color: var(--PrimaryColor);
}
.navBarSection .activeHeader {
  background: rgba(0, 0, 0, 0.8);
  box-shadow: 0 2px 8px 2px rgba(104, 104, 104, 0.45);
}

@media screen and (min-width: 769px) {
  .navBarSection .toggleNavbar, .navBarSection .closeNavbar {
    display: none;
  }
  .navBarSection .header {
    padding: 1.5rem 2rem;
  }
  .navBarSection .header .singleCustomer .customerDetails .name {
    color: var(--whiteColor);
  }
  .navBarSection .header .singleCustomer .customerDetails small {
    color: rgb(206, 204, 204);
  }
  .navBarSection .header .navBar .navLists {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink {
    color: var(--whiteColor);
    padding: 0 0.45rem;
    font-size: 0.9rem;
    font-weight: 400;
  }
  .navBarSection .header .navBar .navLists .navItem .navLink:hover {
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .navItem .active {
    color: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .btn {
    margin-left: 1rem;
  }
  .navBarSection .header .navBar .navLists .btn a {
    color: var(--whiteColor);
    font-weight: 500;
  }
  .navBarSection .header .navBar .navLists .loginBtn {
    background: none;
    border: 1px solid var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .loginBtn:hover {
    background: var(--PrimaryColor);
  }
  .navBarSection .header .navBar .navLists .loginBtn:hover a {
    color: var(--whiteColor);
  }
}
@media screen and (min-width: 960px) {
  .header .navBar .navLists .navItem {
    padding: 0 0.7rem;
  }
}
@media screen and (min-width: 1140px) {
  .header {
    padding: 1rem 8.5rem !important;
  }
  .header .navBar .navLists .navItem {
    padding: 0 0.7rem;
  }
}
@media screen and (max-width: 900px) {
  .contactUsForm {
    width: 100%;
  }
  .userAuthentication {
    width: 300px;
    padding: 2rem;
  }
}
@media screen and (max-width: 400px) {
  .userAuthentication {
    width: 250px;
    padding: 1rem;
  }
}/*# sourceMappingURL=navbar.css.map */