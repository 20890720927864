:root {
  --PrimaryColor: hsl(26,93%,50%);
  --HoverColor: hsl(26,90%,42%);
  --whiteColor: hsl(0,0%,100%);
  --blackColor: hsl(0,0%,10%);
  --textColor: hsl(240,1%,48%);
  --whiteColorDeam: hsl(0,0%,93%);
  --greyBg: hsl(0,0%,96%);
  --greyText: rgb(190,190,190);
  --inputColor: hsl(330,12%,97%);
}

.heroImage .secContainer {
  padding-top: 4rem;
}
.heroImage .secContainer .homeText {
  text-align: center;
}
.heroImage .secContainer .homeText .title {
  color: var(--whiteColor);
  font-size: 2rem;
  font-weight: 700;
  line-height: 2.5rem;
}
.heroImage .secContainer .homeText .subTitle {
  color: var(--whiteColor);
  opacity: 0.9;
  font-size: 13px;
  font-weight: 300;
  padding: 1rem 0;
  max-width: 70%;
  text-align: center;
  margin: auto;
}
.heroImage .secContainer .homeText .btn {
  padding: 0.6rem 1.5rem;
}

@media screen and (min-width: 556px) {
  .heroImage .secContainer .homeText .title {
    font-size: 2.5rem;
  }
  .heroImage .secContainer .homeText .subTitle {
    font-size: 18px;
  }
}
@media screen and (min-width: 720px) {
  .heroImage .secContainer .homeText {
    padding: 1rem 10rem;
  }
  .heroImage .secContainer .homeText .title {
    font-size: 3.5rem;
    margin: auto;
    line-height: 4rem;
  }
  .heroImage .secContainer .homeText .subTitle {
    width: 70%;
  }
}
@media screen and (min-width: 1024px) {
  .heroImage .secContainer .homeText .subTitle {
    font-size: 20px;
  }
}/*# sourceMappingURL=heroimage.css.map */