@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');


*{
    padding:0;
    margin:0;
    box-sizing:border-box;
    font-family: 'Poppins', sans-serif;
    transition: .3s ease;
    background-color: transparent;
}

/* Colors */
:root {
    --PrimaryColor: hsl(26,93%,50%);
    --HoverColor: hsl(26,90%,42%);
    --whiteColor: hsl(0,0%,100%);
    --blackColor: hsl(0,0%,10%);
    --textColor: hsl(240,1%,48%);
    --whiteColorDeam: hsl(0,0%,93%);
    --greyBg: hsl(0,0%,96%);
    --greyText: rgb(190,190,190);
    --inputColor: hsl(330,12%,97%);
    
}

h1,h2,h3,h4,h5,h6{
    color: var(--PrimaryColor);
}

a{
    text-decoration: none;
}

li{
    list-style: none;
}

.section{
    padding: 4rem 0 2rem;
    background-color: transparent;

}

.container{
    background-color: transparent;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.icon{
    font-size:2rem;
    cursor:pointer;
    color: red;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
}

.btn{
    padding: .4rem 1.5rem;
    background: var(--PrimaryColor);
    border: none;
    outline: none;
    border-radius: 3rem;
    cursor: pointer;
}


.btn a{
    color: var(--whiteColor);
    font-weight: 500;
}

.btn:hover a {
    color: var(--whiteColor);
}

.btn:hover {
    background: var(--HoverColor);
}

.secIntro {
  max-width: 70%;
  text-align:center;
  margin: 0 auto 2rem;
}

.secIntro .secTitle{
    color: var(--PrimaryColor);
    line-height: 1.5rem;
    padding-bottom: .5rem;
}

.secIntro p{
    font-size: 13px;
    color: var(--textColor);
    font-weight: 500;
}

img, video{
    width:100%;
    height:auto;
}

input{
    font-size:100%;
}


@media screen and (min-width: 556px) {
    .mainContent, .singlePost{
        grid-template-columns: repeat(2,1fr);
    }
}

@media screen and (min-width: 884px) {
    .mainContent{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (min-width: 1024px) {
    .container{
        width: 75% !important;
        margin: auto !important;
    }


    .section{
        padding: 5rem 0 3rem;
    }

    .secIntro{
        margin: 0 auto 3rem;
    }

    .secIntro .secTitle{
        line-height: 2rem;
        padding-bottom: 1rem;
        font-size: 2.2rem;
    }

    .secIntro p{
        font-size: 15px;
    }
}

@media screen and (min-width: 2560px) {
    body{
        zoom: 1.7;
    }
}


::selection{
    background: var(--PrimaryColor);
    color: var(--whiteColor);
}

::-webkit-scrollbar{
    width: 10px;
    background: var(--greyBg);
}

::-webkit-scrollbar-thumb{
    background: var(--PrimaryColor);
    border-radius: 10px;
    cursor: pointer;
}